import { DeepPartial, IFormGroupSchema } from './common.interface';

export enum RoleCategory {
    Admin = 'ADMIN',
    Restricted = 'RESTRICTED',
}

export enum RoleStatus {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
}

export enum Mode {
    LIST,
    VIEW,
    EDIT,
    CREATE,
}

export interface IRole {
    id: string;
    name: string;
    description?: string;
    category: RoleCategory;
    status: RoleStatus;
    permissions: string[];
}

export interface IRoleList {
    data: IRole[];
    after: string;
    has_more: boolean;
}

export interface RoleNode {
    children: RoleNode[];
    name: string;
    id: string;
    isChecked: boolean;
}

export interface Permissions {
    id: string;
    scope: {
        name: string;
        display_name: string;
        id: string;
        parent_id: string;
        sort_order: number;
        ui_visible: boolean;
        type: string;
    };
}

export interface RoleFlatNode {
    name: string;
    level: number;
    expandable: boolean;
    id: string;
    isChecked: boolean;
}

export type IRoleForm = Omit<IRole, 'id'>;

export enum RoleEnum {
    'ADMIN' = 'Admin',
    'RESTRICTED' = 'Restricted',
}

export interface StatusInfo {
    key: string;
    value: string;
    action: string;
}

export interface IRoleQueryParams {
    name: string;
    category: string;
}

export type IRoleAdvanceFilterFields = Pick<IRoleQueryParams, 'name' | 'category'>;

export type IRoleQueryParamsForm = IFormGroupSchema<DeepPartial<IRoleQueryParams>>;

export interface IRoleQueryParams {
    name: string;
    category: string;
    limit: number;
    after: string;
}
