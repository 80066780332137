import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ILinkAccountCard, IAccountFunds, IAccountFundsResponse, LimitType, ILimit, IListResponse } from '@lib/interfaces';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccountsService {
    environment_url: string;
    reloadAccountsList: EventEmitter<boolean> = new EventEmitter();

    constructor(private http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    fetchAccountDetails(accountId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}/nfront/v1/proxy/accounts/${accountId}`);
    }

    linkAccountCard(payload: ILinkAccountCard): Observable<any> {
        const { accountId, ...requestData } = payload;
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/accounts/${accountId}/cards:link`, requestData);
    }

    fetchAccountCards(accountId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}/nfront/v1/proxy/accounts/${accountId}/cards`);
    }

    changeAccountStatus(accountId: string, status: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}/nfront/v1/proxy/accounts/${accountId}:changeStatus`, {
            status,
        });
    }

    loadUnloadAccountFunds(accountId: string, payload: any, operation: string): Observable<IAccountFundsResponse> {
        return this.http.post<IAccountFundsResponse>(`${this.environment_url}nfront/v1/proxy/accounts/${accountId}:${operation}`, payload, {
            headers: new HttpHeaders({
                'x-nymos-idempotency-key': Math.random(),
            }),
        });
    }

    // Balance Limit In Customers Tab

    getListOfBalanceLimitIds(accountId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/accounts/${accountId}/balancelimits`);
    }
    public fetchLimits(): Observable<Array<ILimit>> {
        return this.http.get<IListResponse<ILimit>>(`${this.environment_url}nfront/v1/proxy/balancelimits?limit=100`).pipe(
            map(({ data }: IListResponse<ILimit>) => {
                return data;
            }),
        );
    }
    getBalanceLimitDropDown(): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/balancelimits`);
    }
    getBalanceLimitDetail(balanceId: string): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/balancelimits/${balanceId}`);
    }
    public linkLimitToAccount(accountId: string, limitIds: Array<string>): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/accounts/${accountId}/balancelimits:link`, { balance_limit_id: limitIds[0] }).pipe(
            map((linkedLimits: Array<string>) => {
                return linkedLimits;
            }),
        );
    }
    public unlinkLimitToAccount(cardProductId: string, limitIds: Array<string>): Observable<Array<string>> {
        return this.http.post<Array<string>>(`${this.environment_url}nfront/v1/proxy/accounts/${cardProductId}/balancelimits:unlink`, { balance_limit_id: limitIds[0] }).pipe(
            map((remainingLimits: Array<string>) => {
                return remainingLimits;
            }),
        );
    }
}
