import { Pipe, PipeTransform } from '@angular/core';
import { OperationCode } from '@lib/interfaces/otp-configuration.interface';
import { Optional } from '@lib/interfaces';

@Pipe({
    name: 'otpOperationCode',
    standalone: true,
})
export class OtpOperationCodePipe implements PipeTransform {
    public transform(value: Optional<OperationCode>): string {
        switch (value) {
            case OperationCode.ACTIVATE_CARD:
                return 'Activate Card';

            case OperationCode.CHANGE_PIN:
                return 'Change PIN';

            case OperationCode.FUNDS_TRANSFER:
                return 'Funds Transfer';

            case OperationCode.LOGIN:
                return 'Login';

            case OperationCode.RESET_PASSWORD:
                return 'Reset Password';

            case OperationCode.REVEAL_CARD:
                return 'Reveal Card';

            case OperationCode.SET_PASSWORD:
                return 'Set Password';

            case OperationCode.SHOW_PIN:
                return 'Show PIN';

            case OperationCode.VERIFY_USER:
                return 'Verify User';

            case OperationCode.DEFAULT:
                return 'Default';

            default:
                return '';
        }
    }
}
