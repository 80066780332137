import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

export const permissionGuard: CanActivateFn = (permissions: ActivatedRouteSnapshot) => {
    if (permissions?.data['permission'].length === 0) return true;

    const allPermissions = localStorage.getItem('permissions')?.split(',');
    const hasPermission = allPermissions?.find((permission) => permissions?.data['permission'].includes(permission));

    return !!hasPermission;
};
